import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton', 'coverImageInput', 'coverImageValue']

  connect() {
    this.showIfErrors()
  }

  validate(event) {
    this.validateDynamicAttributes(event)
    this.validateCoverImage(event)
  }

  validateDynamicAttributes(event) {
    var firstError = null
    var dynamicAttributes = this.element.getElementsByClassName('add-file__form')
    for (let attribute of dynamicAttributes) {
      if ($(attribute).is(':visible')) {
        attribute.getElementsByClassName('buttons-wrapper')[0].classList.add('not-saved-warning')
        firstError = firstError || attribute
      }
    }

    if (firstError) {
      firstError.scrollIntoView()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  validateCoverImage(event) {
    if (this.coverImageValueTarget.getAttribute('src') || this.coverImageInputTarget.files.length > 0 || $("#resource_default")[0].checked) {
      return
    }

    this.coverImageInputTarget.parentElement.classList.add('blank-warning')
    this.coverImageInputTarget.parentElement.scrollIntoView()
    event.preventDefault()
    event.stopPropagation()
  }

  showIfErrors() {
    var firstError = null
    var dynamicAttributes = this.element.getElementsByClassName('add-file__form')
    for (let attribute of dynamicAttributes) {
      var fields = attribute.getElementsByClassName('info-field')
      if (fields.length > 0) {
        attribute.parentElement.classList.add('blank-warning')
        firstError = firstError || attribute.parentElement
      }
    }

    if (firstError) {
      firstError.scrollIntoView()
    }
  }

  removeEmptyAttributes() {
    var dynamicAttributes = this.element.getElementsByClassName('add-file__form')
    var emptyAttributes = []

    for (let attribute of dynamicAttributes) {
      if (Array.prototype.slice.call(attribute.getElementsByTagName('input')).every((currentValue) => currentValue.value.length == 0)) {
        emptyAttributes.push(attribute)
      }
    }

    for (var i = emptyAttributes.length; i > 0; i--) {
      emptyAttributes[i-1].remove()
    }
  }

}
